import React from "react";
import EmailForm from "../components/Kontakt/EmailForm";
import Headline from "../components/Headline";
import KontaktDetails from "../components/Kontakt/KontaktDetails";
import profil from "../images/profil.jpg";
import qrCode from "../images/qr-code.png";

function Kontakt() {
	return (
		<div className="mt-10 font-darkerGrotesque" id="kontakt">
			<div className="mb-5">
				<Headline title="Kontakt" />
			</div>
			{/* <div className='max-w-4xl mb-10 mr-5 ml-5 sm:mx-auto'>
				<h1 className='text-2xl font-bold text-center'>Das Bewährte wieder tun!</h1>
				<br></br>
				<span className='text-xl'>
					Die Wechselwirkung von Mensch, Architektur & Immobilien ist vielseitig interpretierbar. Wir
					verstehen darunter, dass die von uns neu zu gestaltenden Lebens- bzw. Arbeitsräume und deren
					Herstellung folgenden Kriterien entsprechen:
				</span>
				<ul className='list-disc ml-10 mt-3 text-xl'>
					<li>Kostentransparenz und Kostensicherheit für Ihr Bauvorhaben von Anfang an</li>
					<li>Der Kunde ist "König"</li>
					<li>Architektur in Qualität, Gestaltung und Umsetzung</li>
					<li>Einbindung von neusten Technologien</li>
					<li>Die ästhetischen Wünsche der Bauherren/-innen werden erfüllt</li>
				</ul>
				<hr className='mt-10' />
			</div> */}
			<div className="flex flex-col lg:flex-row justify-center lg:mx-auto items-start max-w-4xl min-h-fit mb-5">
				<div className="max-w-xl ml-5 mr-5 lg:mr-12 lg:ml-12 mb-10 self-center lg:self-start">
					<KontaktDetails />
					<img src={qrCode} className="w-24 mt-3" alt="QR-Code" />
				</div>
				<div className="mx-5 lg:mr-12 self-center lg:self-start flex-1 w-80 xs:w-96">
					<h3 className="text-2xl font-bold text-black mb-5 text-center">Schreiben Sie uns</h3>
					<EmailForm />
				</div>
			</div>
			<div className="max-w-4xl mb-10 mr-5 ml-5 md:mx-auto">
				<hr className="mb-5" />
				<div className="flex justify-between items-center text-xl">
					<div>
						<span className="text-2xl font-bold">Axel Maisenhelder</span>
						<br /> Dipl.-Ing. Architekt (FH)
						<br /> Studium an der Fachhochschule Stuttgart
						<br /> Architekt seit 1989
					</div>
					<div>
						<img src={profil} alt="Axel Maisenhelder" />
					</div>
				</div>
				<p className="text-xl">
					<br />
					Herr Axel Maisenhelder, Architekt und Diplomingenieur, zählt seit vielen Jahren private Bauherren und
					Unternehmen aus dem Wohnungsbau zu seinen zufriedenen Auftraggebern. Hier sind Sie garantiert an der richtigen
					Adresse. Sein Netzwerk an Ingenieuren, Handwerkern und Partnern im Immobiliensektor verbindet Kompetenz und
					Kreativität mit routinierten Abläufen. Das Architekturbüro Maisenhelder ist für sie in der Region Heilbronn –
					Franken, Ludwigsburg, Pforzheim, Sinsheim, Bad Rappenau, Möckmühl und Eppingen ihr kompetenter
					Ansprechpartner.
				</p>
				<div className="text-xl mt-10 flex flex-col items-center gap-10 p-5 sm:flex-row sm:justify-around sm:items-start">
					<div className="max-w-md items-center">
						<span className="text-2xl font-bold uppercase">Tätigkeitsfelder</span>
						<div className="">
							<ul className="list-disc ml-10">
								<li>Neubauplanung</li>
								<li>Bauen im Bestand</li>
								<li>An- &amp; Umbauten</li>
								<li>Aufmaßservice</li>
								<li>Denkmalsanierung</li>
							</ul>
						</div>
					</div>
					<div className="max-w-md items-center">
						<span className="text-2xl  font-bold uppercase">Leistungen als Architekt</span>
						<p className="max-w-xs text-center">
							Leistungsphasen 1-8 nach{" "}
							<span className="cursor-pointer font-semibold" aria-label="Honorarordnung für Architekten und Ingenieure">
								HOAI
							</span>
						</p>
						<ul className="list-disc ml-24">
							<li className="list-item">Enwurf</li>
							<li>Bauantrag</li>
							<li>Werkplanung</li>
							<li>Vergabe</li>
							<li>Bauleitung</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Kontakt;
